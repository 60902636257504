<template>
  <div>
    <index-slider class="d-block d-sm-none" />
    <catalog />
    <quetions class="question-catalog" />
  </div>
</template>

<script>
import Catalog from "@/components/components/Catalog/Catalog";
import Quetions from "@/components/index/Quetions";
import IndexSlider from "@/components/index/IndexSlider";
export default {
  name: "CatalogPage",
  head: {
    title: function () {
      return {
        inner: this.$route.meta?.title || "Прокат авто",
      };
    },
  },
  components: {
    Quetions,
    Catalog,
    IndexSlider,
  },
};
</script>

<style scoped lang="scss">
.question-catalog {
  padding-top: 150px;
}
</style>
